<template>
  <main>
    <sqr-hero
      title="password_change_title"
      subtitle="password_change_subtitle"
      color="light"
      is-bold
    />
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-6-tablet is-4-desktop">
            <error-notifications :errors="errors"/>
            <div v-if="!success">
              <!-- <h1 class="title">{{$t('password_change_title')}}</h1> -->
              <div class="field">
                <sqr-input-password
                  class="field"
                  name="password_new"
                  label="password_change_password_new"
                  v-model="passwordNew"
                  :valid="!$v.passwordNew.$invalid"
                  :invalid="$v.passwordNew.$error"
                  size="medium"
                />
                <div class="help is-danger" v-if="$v.passwordNew.$error && !$v.passwordNew.required">{{$t('validation_required')}}</div>
                <div class="help is-danger" v-else-if="$v.passwordNew.$error && !$v.passwordNew.minLength">{{$t('validation_min_length')}}</div>
              </div>

<div class="field">
              <sqr-input-password
                class="field"
                name="password_new"
                v-model="passwordConfirm"
                label="password_change_password_confirm"
                :valid="!$v.passwordConfirm.$invalid"
                :invalid="$v.passwordConfirm.$error"
                size="medium"
              />
                <div class="help is-danger" v-if="$v.passwordConfirm.$error && !$v.passwordConfirm.required">{{$t('validation_required')}}</div>
                <div class="help is-danger" v-else-if="$v.passwordConfirm.$error && !$v.passwordConfirm.sameAs">{{$t('validation_not_same')}}</div>
              </div>
              <div class="field">
                <div class="buttons is-right">
                  <sqr-button
                    label="password_change_confirm"
                    color="primary"
                    size="large"
                    @click="changePassword()"
                    :is-loading="loading"
                  />
                </div>
              </div>
            </div>
            <div v-if="success">
              <div class="notification is-success is-size-4">
                <fa :icon="['fal', 'check-circle']"/>
                &ensp;{{$t('password_change_success')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { validationMixin } from 'vuelidate';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';

import SqrInputPassword from '@/sqrd/SqrInputPassword';
import SqrButton from '@/sqrd/SqrButton';

export default {
  name: 'PasswordChange',
  mixins: [validationMixin],
  components: { SqrButton, SqrInputPassword },
  computed: {
    ...mapGetters('auth', ['errors'])
  },
  data() {
    return {
      loading: false,
      success: false,
      passwordNew: '',
      passwordConfirm: ''
    };
  },
  validations: {
    passwordNew: { required, minLength:minLength(6) },
    passwordConfirm: { required, sameAs: sameAs('passwordNew') }
  },
  methods: {
    ...mapActions('auth', ['passwordChange']),
    async changePassword() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      try {
        this.loading = true;
        const password = this.passwordNew;
        const user = this.$fb().auth().currentUser;
        await user.updatePassword(password);
        this.success = true;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    }
  }
};
</script>
